import { browser } from "protractor";
import { AfterContentInit, Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { FireService } from "src/app/services/fire.service";
import { NodeService } from "src/app/services/node.service";
import { GlobalService } from "src/app/shared/global.service";
import { User } from "src/app/entities/users";
import { ChartType, GoogleChartComponent } from "angular-google-charts";
import { error } from "console";
import { firstValueFrom } from "rxjs/internal/firstValueFrom";

@Component({
  selector: "app-edit-valutazioni",
  templateUrl: "./edit-valutazioni.component.html",
  styleUrls: ["./edit-valutazioni.component.scss"],
})
export class EditValutazioniComponent implements OnInit, AfterContentInit {
  @ViewChild("chart") gChart: any;

  search: string = "";
  isCollapsed = false;
  aId: string = "";
  lId: string = "";
  cId: string = "";
  loggedIn: User;
  data: any[] = [];
  displayedData: any[] = [];
  intestazione: any[] = [];
  questionario: any[] = [];
  loading: boolean = true;
  loadingChart: boolean = true;
  valText: string = "";
  scroll = { y: "" };
  bSave: boolean = false;


  // ######################################
  // FUNCTION: constructor
  constructor(
    private location: Location,
    public auth: AuthService,
    private route: ActivatedRoute,
    private fire: FireService,
    private node: NodeService,
    public g: GlobalService
  ) { }

  // ######################################
  // EVENT: onResize
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    event.target.innerWidth;
    this.setScrollHeight();

    this.g.chartData.forEach((c, index) => {
      if (event.target.innerWidth < 1240) {
        c[0] = this.questionario["tratti"][index].codice;
      } else {
        c[0] = this.questionario["tratti"][index].titolo["ITA"];
      }
    });
    this.g.chartData = [...this.g.chartData];
  }

  // ######################################
  // FUNCTION: setScrollHeight
  setScrollHeight() {
    if (document.getElementsByClassName("uiGestioneEditVTable").length > 0) {
      let height = document.getElementsByClassName("uiGestioneEditVTable")[0].clientHeight;
      if (document.getElementsByClassName("uiGestioneEditVTable")[0].getElementsByClassName("ant-table-thead").length) {
        let headH = document
          .getElementsByClassName("uiGestioneEditVTable")[0]
          .getElementsByClassName("ant-table-thead")[0].clientHeight;
        this.scroll = { y: height - headH + "px" };
      } else {
        this.scroll = { y: height - 24 + "px" };
      }
    }
  }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit() {
    this.loggedIn = this.auth.getUser();
    this.route.params.subscribe((param) => {
      this.aId = param.aId;
      this.lId = param.lId;
      this.cId = param.cId;
    });

    this.fire.getSubDoc("clienti", this.aId, "licenze", this.lId, "compilazioni", this.cId).subscribe((res) => {
      this.data[0] = res;
      this.displayedData[0] = res;

      if (this.data[0].commento) {
        this.valText = this.data[0].commento;
      }
      this.createData();
      this.loading = false;
      // setTimeout(() => {
      this.setScrollHeight();
      // }, 200);
    });

    // ['R1', 20, 'color: #661615;', 20],
    // ['R2', 40, 'color: #661615', 40],
    // ['R3', 60, 'color: #661615', 60],
    // ['R4', 80, 'color: #661615', 80],
    // ['R5', -20, 'color: #661615', -20],
    // ['R6', -40, 'color: #661615', -40],
    // ['R7', -60, 'color: #661615', -60],
    // ['R8', -80, 'color: #661615', -80]
  }

  ngAfterContentInit(): void {

  }

  // ######################################
  // FUNCTION: back
  back() {
    this.location.back();
  }



  // ######################################
  // FUNCTION: createData
  createData() {
    this.fire
      .getDocument("clienti", this.data[0].licenza.azienda, "licenze", this.data[0].licenza.licenza)
      .subscribe(async (lic) => {
        await this.fire.getDocument("questionari", lic.questionario, "versioni", lic.versione).subscribe((q) => {
          this.questionario = q;
          this.intestazione = [];
          this.bSave = this.questionario["report"] != 2 ? true : false;

          // CREO L'INSTESTAZIONE NEL GIUSTO ORDINE
          for (let key in this.data[0].intestazione) {
            var rec = q.anagrafica.find((a) => a.etichetta == key);

            this.intestazione.push({
              ordine: rec ? rec.ordine : 99,
              etichetta: key,
              valore: this.data[0].intestazione[key],
            });
          }
          this.intestazione.sort((a, b) => {
            if (a.ordine == b.ordine) return a.etichetta > b.etichetta ? 1 : -1;
            else return a.ordine > b.ordine ? 1 : -1;
          });

          // #################
          this.g.chartDisplay(this.data[0], this.questionario);
          window.dispatchEvent(new Event('resize'));
        });
      });
  }

  // ######################################
  // FUNCTION: save
  save(isNotification = true) {
    this.data[0].commento = this.valText;
    this.fire.updateSubDoc("clienti", "licenze", "compilazioni", this.data[0], this.aId, this.lId, this.cId);
    if (isNotification)
      this.g.createSuccessNotification("Completato", "Il suo commento è stato salvato");
  }

  // ######################################
  // FUNCTION: saveAndExit
  saveAndExit() {
    this.save();
    this.location.back();
  }


  async sendCommentNotification(): Promise<void> {
    try {
      await this.g.createSuccessNotification("Completato", "Il suo commento è stato inviato");
      this.save(false);

      const reparti = await firstValueFrom(this.fire.getCollection("clienti", this.aId, "reparti"));
      const allUtenti: { repId: string; utenti: { admin: boolean; email: string }[] }[] = [];

      for (const rep of reparti) {
        try {
          const utenti = await firstValueFrom(this.fire.getSubCollection("clienti", this.aId, "reparti", rep.id, 'utenti'));
          allUtenti.push({ repId: rep.id, utenti });
        } catch (error) {
          console.error(`Error fetching utenti for rep ${rep.id}:`, error);
          allUtenti.push({ repId: rep.id, utenti: [] }); // Push an empty array if there's an error
        }
      }

      const allAdminEmails = allUtenti.reduce<string[]>((accumulator, elem) => {
        if (Array.isArray(elem.utenti)) {
          const adminEmails = elem.utenti
            .filter(user => user.admin === true)
            .map(user => user.email);
          return accumulator.concat(adminEmails);
        }
        return accumulator; // Return the accumulator unchanged if 'utenti' is not an array
      }, []);

      if (allAdminEmails.length === 0) {
        console.warn("No admin emails found.");
        return; // Exit early if no admin emails exist
      }

      let intestazione = this.displayedData[0].intestazione;

      await Promise.all(allAdminEmails.map(async (managerEmail) => {
        let formData = {
          manager: managerEmail,
          azienda: intestazione.Azienda,
          ruolo: intestazione.Ruolo,
          nominativo: intestazione.Nome + " " + intestazione.Cognome,
        };
        await this.g.sendMailNotification(formData, "/users/sendNotificationManager");
      }));

    } catch (error) {
      console.error("Error in sendCommentNotification:", error);
    }



  }





}
