import { Questionario } from "./../entities/questionari";
import { environment } from "./../../environments/environment";
import { ElementRef, Injectable, ViewChild } from "@angular/core";
// @ts-ignore
import crc32 from "crc/crc32";
import { NzNotificationDataOptions, NzNotificationService } from "ng-zorro-antd/notification";
import { NzMessageService } from "ng-zorro-antd/message";
import { SodiumPlus, CryptographyKey } from "sodium-plus";
import { User } from "../entities/users";
import { FireService } from "src/app/services/fire.service";
import html2canvas from "html2canvas";
import { HttpClient, HttpEvent, HttpParams, HttpRequest, HttpResponse } from "@angular/common/http";
import { NodeService } from "../services/node.service";
import { ChartType } from "angular-google-charts";
import { NzModalService } from "ng-zorro-antd/modal";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  // yy.mm.dd
  appVersion = "24.08.22";
  globalFooter: boolean = true;
  actualPage = "";
  selectedLanguage = "ITA";
  selectedQuote = "";
  selectedVersion = "";
  keyBuffer!: Buffer;
  nodePath: string;
  sodium!: SodiumPlus;
  loggedInUser: User;
  languages = ["ITA", "ENG"];
  temp_quest: any;
  temp_quest_lang: string = "ITA";
  activeId: string = "";
  temp_quest_id: string = "";
  uploadButtons = {
    showRemoveIcon: false,
    showDownloadIcon: false,
  };
  questLoaded = false;
  temp_quest_questions: any = [];
  temp_quest_traits: any = [];

  listOfPermissions = [
    { label: "Designer", icona: "customIcon uiGreyDesignerIcon" },
    // {label:'Simulazione', icona: 'customIcon uiGreySimulazioneIcon'},
    { label: "Pubblicazione", icona: "customIcon uiGreyPubblicazioneIcon" },
    { label: "Utenti e gruppi", icona: "customIcon uiGreyUtentigruppiIcon" },
    {
      label: "Clienti e aziende",
      icona: "customIcon uiGreyClientiaziendeIcon",
    },
    {
      label: "Gestione questionari",
      icona: "customIcon uiGreyGestionequestIcon",
    },
    {
      label: "Gestione destinatari",
      icona: "customIcon uiGreyGestionedestIcon",
    },
    { label: "Gestione aggregati", icona: "customIcon uiGreyGestioneaggrIcon" },
    { label: "Valutazioni", icona: "customIcon uiGreyValutazioniIcon" },
    { label: "Privacy", icona: "customIcon uiGreyPrivacyIcon" },
  ];

  dataITtranslation = {
    lang: {
      locale: "it_IT",
      placeholder: "Seleziona data",
      rangePlaceholder: ["Data inizio", "Data fine"],
      today: "Oggi",
      now: "Ora",
      backToToday: "Ritorna ad oggi",
      ok: "OK",
      clear: "Annulla",
      month: "Mese",
      year: "Anno",
      timeSelect: "Seleziona ora",
      dateSelect: "Seleziona data",
      monthSelect: "Scegli mese",
      yearSelect: "Scegli anno",
      decadeSelect: "Scegli decade",
      yearFormat: "YYYY",
      dateFormat: "dd/MM/yyyy",
      dayFormat: "dd",
      dateTimeFormat: "dd/MM/yyyy HH:mm:ss",
      monthFormat: "MM",
      monthBeforeYear: true,
      previousMonth: "Mese precende",
      nextMonth: "Mese successivo",
      previousYear: "Anno precedente",
      nextYear: "Anno successivo",
      previousDecade: "Decade precedente",
      nextDecade: "Decade successiva",
      previousCentury: "Secolo scorso",
      nextCentury: "Secolo successivo",
      yearPlaceholder: "Seleziona anno",
      monthPlaceholder: "Seleziona mese",
      weekPlaceholder: "Seleziona settimana",

      rangeYearPlaceholder: ["Anno inizio", "Anno fine"],
      rangeQuarterPlaceholder: ["Start quarter", "End quarter"],
      rangeMonthPlaceholder: ["Mese inizio", "Mese fine"],
      rangeWeekPlaceholder: ["Settimana inizio", "Settimana fine"],
    },
    timePickerLocale: {
      placeholder: "Seleziona ora",
    },
    dateFormat: "dd/MM/yyyy",
    dateTimeFormat: "dd/MM/yyyy HH:mm:ss",
    weekFormat: "YYYY-wo",
    monthFormat: "YYYY-MM",
  };
  CryptoJS = require("crypto-js");

  // ######################################
  // FUNCTION: constructor
  constructor(
    private notification: NzNotificationService,
    private message: NzMessageService,
    private fire: FireService,
    private http: HttpClient,
    private node: NodeService,
    private modal: NzModalService
  ) {
    this.nodePath = this.node.baseUrl;

    // SUBSCRIBE DISCONECTION EVENT
  }

  // ######################################
  // FUNCTION: saveKey
  saveKey(data: string) {
    this.keyBuffer = Buffer.from(data, "utf8");
  }

  // ######################################
  // FUNCTION: encryptDataGlobal
  async encryptDataGlobal(data: string) {
    const crc32Key = crc32(data);
    const base64Key = btoa(crc32Key.toString());

    if (!this.sodium) {
      this.sodium = await SodiumPlus.auto();
    }
    const nonce = await this.sodium.randombytes_buf(24);
    const keyPair = new CryptographyKey(this.keyBuffer);
    const sKey = await this.sodium.crypto_box_secretkey(keyPair);
    const pKey = await this.sodium.crypto_box_publickey(keyPair);
    const encrypted = await this.sodium.crypto_box(base64Key, nonce, sKey, pKey);

    return nonce.toString("hex") + encrypted.toString("hex");
  }

  // ######################################
  // FUNCTION: randomMath
  randomMath() {
    return Math.random();
  }

  // ######################################
  // FUNCTION: createSuccessNotification
  createSuccessNotification(title: string, message: string, duration: number = 4500) {
    this.notification.create("success", title, message, { nzDuration: duration });
  }

  // ######################################
  // FUNCTION: createErrorNotification (popup on topright with close)
  createErrorNotification(title: string, message: string, duration: number = 4500) {
    this.notification.create("error", title, message, { nzDuration: duration });
  }

  // ######################################
  // FUNCTION: createCustomNotification (popup on topright with close)
  createCustomNotification(type: string, title: string, message: string, options?: NzNotificationDataOptions) {
    this.notification.create(type, title, message, options);
  }

  // ######################################
  // FUNCTION: createCustomMessage (popup on top without close)
  createCustomMessage(type: string, message: string) {
    this.message.create(type, message);
  }

  // ######################################
  // FUNCTION: dynamicSort
  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  // ######################################
  // FUNCTION: dynamicNumberSort
  dynamicNumberSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      return (
        a[property].localeCompare(b[property], undefined, {
          numeric: true,
          sensitivity: "base",
        }) * sortOrder
      );
    };
  }

  // ######################################
  // FUNCTION: chartDisplay
  public chartProgress: boolean = false;
  public chartSave: boolean = false;
  public chartData: any[] = [];
  public chartVisible: boolean = false;

  public chartType: ChartType = ChartType.ColumnChart;
  public chartBarType: ChartType = ChartType.ColumnChart;
  public chartLineType: ChartType = ChartType.LineChart;

  // ## CHART COLUMNS NAMES
  public chartColumnNames: any = ["tratto", "value"];
  public chartBarColumnNames = [
    "tratto",
    "value",
    { role: "style" },
    { role: "annotation" },
    { role: "tooltip", type: "string", p: { html: true } },
  ];
  public chartLineColumnNames = [
    "tratto",
    "value",
    { role: "style" },
    { role: "annotation" },
    { role: "tooltip", type: "string", p: { html: true } },
  ];
  // ## CHART PRINT OPTIONS
  public chartPrintOptions = {};
  public chartBarPrintOptions = {
    title: "",
    backgroundColor: "transparent",
    width: "100%",
    height: "100%",
    enableInteractivity: true,
    axisTitlesPosition: "out",
    isStacked: true,
    chartArea: {
      left: "5%",
      top: "5%",
      height: "86%",
      width: "92%",
    },

    fontSize: 17,
    legend: { position: "none" },

    vAxis: {
      textPosition: "out",
      ticks: [
        { v: -100, f: "-100" },
        { v: -80, f: "-80" },
        { v: -60, f: "-60" },
        { v: -40, f: "-40" },
        { v: -20, f: "-20" },
        { v: 0, f: "0" },
        { v: 20, f: "20" },
        { v: 40, f: "40" },
        { v: 60, f: "60" },
        { v: 80, f: "80" },
        { v: 100, f: "100" },
      ],
      viewWindow: {
        max: 100,
        min: -100,
      },
      textStyle: { color: "#303030" },
      gridlines: {
        color: "#aaa",
      },
    },
    hAxis: {
      textPosition: "out",
      //slantedText: true,
      // slantedTextAngle: 90,
      textStyle: {
        fontSize: 12,
        // direction: -1,
      },
    },
  };
  public chartLinePrintOptions = {
    title: "",
    backgroundColor: "transparent",
    width: "100%",
    height: "100%",
    enableInteractivity: true,
    axisTitlesPosition: "out",
    isStacked: true,
    pointShape: "triangle",
    pointSize: 30,
    colors: ["#000"],
    chartArea: {
      left: "5%",
      top: "5%",
      height: "86%",
      width: "92%",
    },

    fontSize: 17,
    legend: { position: "none" },

    vAxis: {
      textPosition: "out",
      ticks: [
        { v: -100, f: "-100" },
        { v: -80, f: "-80" },
        { v: -60, f: "-60" },
        { v: -40, f: "-40" },
        { v: -20, f: "-20" },
        { v: 0, f: "0" },
        { v: 20, f: "20" },
        { v: 40, f: "40" },
        { v: 60, f: "60" },
        { v: 80, f: "80" },
        { v: 100, f: "100" },
      ],
      viewWindow: {
        max: 100,
        min: -100,
      },
      textStyle: {
        color: "#303030",
        fontSize: 18,
      },
      gridlines: {
        color: "#aaa",
      },
    },
    hAxis: {
      textPosition: "out",
      textStyle: {
        fontSize: 22,
      },
    },
    annotations: {
      textStyle: {
        bold: false,
        opacity: 1,
        fontSize: 28,
      },
      stem: {
        length: 38,
      },
    },
  };
  // ## CHART DISPLAY OPTIONS
  public chartDisplayOptions = {};
  public chartBarDisplayOptions = {
    title: "",
    backgroundColor: "transparent",
    tooltip: {
      isHtml: true,
      trigger: "selection",
    },
    responsive: true,
    width: "100%",
    height: "100%",
    enableInteractivity: true,
    axisTitlesPosition: "out",
    isStacked: true,
    chartArea: {
      left: "5%",
      top: "5%",
      height: "86%",
      width: "92%",
    },
    fontSize: 17,
    legend: { position: "none" },

    vAxis: {
      textPosition: "out",
      ticks: [
        { v: -100, f: "-100" },
        { v: -80, f: "-80" },
        { v: -60, f: "-60" },
        { v: -40, f: "-40" },
        { v: -20, f: "-20" },
        { v: 0, f: "0" },
        { v: 20, f: "20" },
        { v: 40, f: "40" },
        { v: 60, f: "60" },
        { v: 80, f: "80" },
        { v: 100, f: "100" },
      ],
      viewWindow: {
        max: 100,
        min: -100,
      },
      textStyle: { color: "#303030" },
      gridlines: {
        color: "#aaa",
      },
    },
    hAxis: {
      textStyle: { color: "#303030", fontSize: 12 },
    },
    annotations: {
      textStyle: {
        bold: false,
        opacity: 1,
        fontSize: 28,
      },
    },
  };
  public chartLineDisplayOptions = {
    title: "",
    backgroundColor: "transparent",
    tooltip: {
      isHtml: true,
      trigger: "selection",
    },
    responsive: true,
    width: "100%",
    height: "100%",
    enableInteractivity: true,
    axisTitlesPosition: "out",
    isStacked: true,
    pointShape: "triangle",
    pointSize: 30,
    chartArea: {
      left: "5%",
      top: "5%",
      height: "86%",
      width: "92%",
    },
    fontSize: 17,
    legend: { position: "none" },

    vAxis: {
      textPosition: "out",
      ticks: [
        { v: -100, f: "-100" },
        { v: -80, f: "-80" },
        { v: -60, f: "-60" },
        { v: -40, f: "-40" },
        { v: -20, f: "-20" },
        { v: 0, f: "0" },
        { v: 20, f: "20" },
        { v: 40, f: "40" },
        { v: 60, f: "60" },
        { v: 80, f: "80" },
        { v: 100, f: "100" },
      ],
      viewWindow: {
        max: 100,
        min: -100,
      },
      textStyle: { color: "#303030" },
      gridlines: {
        color: "#aaa",
      },
    },
    hAxis: {
      textStyle: { color: "#303030", fontSize: 12 },
    },
    annotations: {
      textStyle: {
        bold: false,
        opacity: 1,
        fontSize: 22,
      },
      stem: {
        length: 28,
      },
    },
  };
  // ####################
  chartDisplay(data: any, q: any) {

    q.tratti.forEach((t, tIndex: number) => {
      let pCount = 0;
      data.domande.forEach((d) => {
        let p = t.punteggi[d.domanda];
        if (p) {
          let dIndex = p.domanda.risposte.findIndex((di) => di.codice === d.risposta);
          if (dIndex >= 0) {
            let r = p.domanda.risposte[dIndex];
            pCount += parseFloat(r.valore) || 0;
          }
        }
      });

      let tCount = pCount;
      this.chartType = this.chartBarType;
      this.chartDisplayOptions = this.chartBarDisplayOptions;
      this.chartColumnNames = this.chartBarColumnNames;

      if (q["report"] == 2) {
        this.chartType = this.chartLineType;
        this.chartDisplayOptions = this.chartLineDisplayOptions;
        this.chartColumnNames = this.chartLineColumnNames;

        // ######################## SCALATURA NUOVO ALGORITMO
        const scalato = ((tCount - t.min) / (t.max - t.min)) * 200 - 100;
        tCount = Math.round(scalato);
      } else if (t.transform) {
        let pIndex = t.transform.findIndex((tr) => tr.punti === pCount);
        if (pIndex > -1) {
          tCount = t.transform[pIndex].trasformato;
        }
      }
      this.chartData[tIndex] = [];
      this.chartData[tIndex].push(window.innerWidth < 1200 ? t.codice : t.titolo["ITA"]);
      this.chartData[tIndex].push(tCount);
      if (q["report"] != 2) {
        if (t.gruppo === 1) {
          this.chartData[tIndex].push("color: #FD0000;");
        } else if (t.gruppo === 2) {
          this.chartData[tIndex].push("color: #E97949;");
        } else if (t.gruppo === 3) {
          this.chartData[tIndex].push("color: #00B0F0;");
        } else if (t.gruppo === 4) {
          this.chartData[tIndex].push("color: #92D050;");
        } else if (t.gruppo === 5) {
          this.chartData[tIndex].push("color: #FFFF00;");
        } else {
          this.chartData[tIndex].push("color: #661615;");
        }
      }
      this.chartData[tIndex].push(tCount);
      this.chartData[tIndex].push(
        '<div style="padding:15px 15px 15px 15px; color: #000">' +
        "<table>" +
        "<tr>" +
        t.titolo["ITA"] +"</tr>" +
        "</table>" +
        "</div>"
      );

      if (tIndex === q.tratti.length - 1) {
        this.chartProgress = false;
      }
    });
  }

  extractExtraQuestions(questions, extraQuestions, surveyDetails): any {
    let personalComments: string[] = [];

    const questionTitle: string[] = [];
    for (let i = questions.length - extraQuestions; i <= questions.length - 1; i++) {
      let onlyQuestionString = surveyDetails.domande[i].titolo['ITA'];
      if (onlyQuestionString.includes('\n')) onlyQuestionString = onlyQuestionString.replaceAll('\n', ' ');
      questionTitle.push(onlyQuestionString);
      if (questions[i].commento === '') {
        const value = surveyDetails.domande[i].risposte.find(answer => answer.codice === questions[i].risposta);
        const aswerString = value.titolo['ITA'];
        personalComments.push(aswerString);

      } else {
        if (questions[i].commento.includes('\n')) questions[i].commento = questions[i].commento.replaceAll('\n', '""');
        personalComments.push(questions[i].commento);
      }
    }
    return { personalComments, questionTitle };
  }

  // ######################################### EXPORT PER EXCEL
  async chartGroupExportExcell(licenza, reparti, fromExcelButton, extraQuestions) {
    this.chartType = this.chartLineType;

    const repartiId = reparti.map((r) => r.id);
    const repartiNome = reparti.map((r) => r.nome);

    let nCompilazioni: number = 0;
    this.chartProgress = true;

    //// console.log(reparti);
    //// console.log(licenza);
    let tempR: any[] = [];
    const compilationPromises = licenza.compilazioni.map(async (compilazione) => {
      const rep = compilazione.id.split("U")[0];
      // console.log(compilazione);

      // VERIFICO SE LA COMPILAZIONE È NEI REPARTI SELEZIONATI
      if (repartiId.includes(rep)) {
        nCompilazioni++; // AUMENTO IL NUMERO DI COMPILAZIONI VALIDE
        if (fromExcelButton.comment) {
          let personalComments: string[] = [];
          let questionTitle: string[] = [];
          // ################### section for extract extra question ##############################
          if (extraQuestions !== null) {
            // api to get survey data
            const result = await this.fire.getDocOnce("clienti", compilazione.licenza.azienda, "licenze", compilazione.licenza.licenza).toPromise();
            const surveyData = result.data();

            // api that get aswers label and question
            const data = await this.fire.getDocOnce('questionari', surveyData.questionario, 'versioni', surveyData.versione).toPromise();
            const surveyDetails = data.data();

            // function that extract comment and questions
            personalComments = this.extractExtraQuestions(compilazione.domande, extraQuestions, surveyDetails).personalComments;
            questionTitle = this.extractExtraQuestions(compilazione.domande, extraQuestions, surveyDetails).questionTitle;
          } else {
            // ############ section that exrtract all comment #################
            compilazione.domande.forEach(question => {
              if (question.commento === '') question.commento = '""';
              if (question.commento.includes('\n')) question.commento = question.commento.replaceAll('\n', ' ');
              personalComments.push(question.commento);
            });
          }
          tempR[0] = questionTitle.join(';');
          tempR.push(personalComments.join(';'));

        } else {
          // ############ section that extract only aswers ################
          compilazione.domande.forEach((d, index) => {
            const rIndex = parseFloat(d.domanda.substring(1));

            if (!tempR[rIndex]) tempR[rIndex] = [0, 0, 0, 0, 0];
            const nRisposta: number = parseFloat(d.risposta.substring(1)) - 1;
            tempR[rIndex][nRisposta] = tempR[rIndex][nRisposta] + 1;
          });
        }
      }
    });

    await Promise.all(compilationPromises);

    let outCsv = "";
    let fileName = '';
    if (fromExcelButton.comment) {
      outCsv = await tempR.join('; \n');
      fileName = 'comm-';
    } else {
      // console.log("Numero compilazioni: " + nCompilazioni);
      // console.log(tempR);
      tempR.forEach((t) => {
        outCsv += t.join(";") + "\n";
      });
      fileName = 'risp-';
    }

    // create filename
    let idrReparti = '';
    let nameReparti = '';
    reparti.forEach((reparto, index) => {
      if (index > 0) {
        idrReparti += '-' + reparto.id;
        nameReparti += '-' + reparto.nome;
        return;
      }
      idrReparti += reparto.id;
      nameReparti += reparto.nome;
    });
    fileName += nameReparti + '-' + licenza.id + idrReparti + '.csv';


    this.fileExcelExport(outCsv, fileName);
    // const selBox = document.createElement("textarea");
    // selBox.style.position = "fixed";
    // selBox.style.left = "0";
    // selBox.style.top = "0";
    // selBox.style.opacity = "0";
    // selBox.value = outCsv;
    // document.body.appendChild(selBox);
    // selBox.focus();
    // selBox.select();
    // document.execCommand("copy");
    // document.body.removeChild(selBox);
    // nCompilazioni = 0;

    this.chartProgress = false;
    // this.message.success('Dati copiati con successo');
    // ######################################### End EXPORT PER EXCEL
  }

  chartGroupExport(licenza, reparti) {

    this.chartType = this.chartLineType;

    const repartiId = reparti.map((r) => r.id);
    const repartiNome = reparti.map((r) => r.nome);;

    let nCompilazioni: number = 0;
    this.chartProgress = true;

    // ######################################### EXPORT PER EXCEL
    // //// console.log(reparti);
    // //// console.log(licenza);
    // let tempR: any[] = [];

    // licenza.compilazioni.forEach((compilazione) => {
    //   const rep = compilazione.id.split("U")[0];
    //   // VERIFICO SE LA COMPILAZIONE È NEI REPARTI SELEZIONATI
    //   if (repartiId.includes(rep)) {
    //     nCompilazioni++; // AUMENTO IL NUMERO DI COMPILAZIONI VALIDE

    //     compilazione.domande.forEach((d, index) => {
    //       const rIndex = parseInt(d.domanda.substring(1));

    //       if (!tempR[rIndex]) tempR[rIndex] = [0, 0, 0, 0, 0];
    //       const nRisposta: number = parseInt(d.risposta.substring(1)) - 1;
    //       tempR[rIndex][nRisposta] = tempR[rIndex][nRisposta] + 1;
    //     });
    //   }
    // });

    // console.log("Numero compilazioni: " + nCompilazioni);

    // let outCsv = "";
    // tempR.forEach((t) => {
    //   outCsv += t.join(";") + "\n";
    // });

    // const selBox = document.createElement("textarea");
    // selBox.style.position = "fixed";
    // selBox.style.left = "0";
    // selBox.style.top = "0";
    // selBox.style.opacity = "0";
    // selBox.value = outCsv;
    // document.body.appendChild(selBox);
    // selBox.focus();
    // selBox.select();
    // document.execCommand("copy");
    // document.body.removeChild(selBox);
    // nCompilazioni = 0;
    // ######################################### EXPORT PER EXCEL

    this.fire.get("questionari", licenza.questionario).subscribe((quest) => {
      this.fire.getDocument("questionari", licenza.questionario, "versioni", licenza.versione).subscribe((q) => {
        q.tratti.forEach((t, tIndex: number) => {
          // PER OGNI TRATTO DEL QUESTIONARIO
          let pCount = 0;
          licenza.compilazioni.forEach((compilazione) => {
            // VERIFICO SE LA COMPILAZIONE È NEI REPARTI SLEEZIONATI
            if (repartiId.includes(compilazione.id.substr(0, compilazione.id.indexOf("U")))) {
              if (tIndex == 0) nCompilazioni++; // AUMENTO IL NUMERO DI COMPILAZIONI VALIDE

              compilazione.domande.forEach((d) => {
                let p = t.punteggi[d.domanda];
                if (p) {
                  let dIndex = p.domanda.risposte.findIndex((di) => di.codice === d.risposta);
                  if (dIndex >= 0) {
                    let r = p.domanda.risposte[dIndex];
                    pCount += parseFloat(r.valore) || 0;
                  }
                }
              });
            }
          });

          let tCount = pCount;
          this.chartType = this.chartBarType;
          this.chartDisplayOptions = this.chartBarPrintOptions;
          this.chartColumnNames = this.chartBarColumnNames;

          if (q["report"] == 2) {
            this.chartType = this.chartLineType;
            this.chartDisplayOptions = this.chartLinePrintOptions;
            this.chartColumnNames = this.chartLineColumnNames;

            // ######################## SCALATURA NUOVO ALGORITMO
            const scalato =
              ((tCount - t.min * nCompilazioni) / (t.max * nCompilazioni - t.min * nCompilazioni)) * 200 - 100;
            tCount = Math.round(scalato);


            // ####################### DEBUG
            // console.log(tIndex, "COUNT", pCount, "SCALATO", tCount, "MIN", t.min, "MAX", t.max, "COMP", nCompilazioni);
          } else if (t.transform) {
            let pIndex = t.transform.findIndex((tr) => tr.punti === pCount);
            if (pIndex > -1) {
              tCount = t.transform[pIndex].trasformato;
            }
          }

          this.chartData[tIndex] = [];
          this.chartData[tIndex].push(t.titolo["ITA"]);
          this.chartData[tIndex].push(tCount);
          if (q["report"] == 2) {
            if (t.gruppo === 1) {
              this.chartData[tIndex].push("color: #FD0000;");
            } else if (t.gruppo === 2) {
              this.chartData[tIndex].push("color: #E97949;");
            } else if (t.gruppo === 3) {
              this.chartData[tIndex].push("color: #00B0F0;");
            } else if (t.gruppo === 4) {
              this.chartData[tIndex].push("color: #92D050;");
            } else if (t.gruppo === 5) {
              this.chartData[tIndex].push("color: #FFFF00;");
            } else {
              this.chartData[tIndex].push("color: #661615;");
            }
          }
          this.chartData[tIndex].push(tCount);
          this.chartData[tIndex].push("");
          if (tIndex === q.tratti.length - 1) {
            this.chartVisible = true;
            setTimeout(() => {
              let filename = licenza.id + repartiId.join("-") + "-chart.svg";

              let docEl: any = document.getElementById("chart");
              docEl = document.querySelector("svg");
              docEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
              docEl.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
              const formData = {
                "file": docEl.outerHTML,
                "filename": filename
              };


              formData.file = formData.file.replace(/<path [^>]*?stroke="[^"]*"/g, (match) => {
                return match.replace(/stroke="[^"]*"/, 'stroke="#000000"');
              });

              const data = this.node.crypto(formData);
              let baseUrl: string;
              if (location.hostname === "localhost" || location.hostname === "127.0.0.1" || location.hostname === "collapp.amajorsb.com") {
                baseUrl = "https://collnode.amajorsb.com";
              } else {
                baseUrl = "https://node.amajorsb.com";
              }
              const req = new HttpRequest("POST", baseUrl + "/quest/upload/charts", { data }, {
                reportProgress: true,
              });
              return this.http.request(req).subscribe(
                (event: HttpEvent<any>) => {
                  this.chartVisible = false;
                  if (event instanceof HttpResponse) {
                    var copertina = q.copertina_report["ITA"];
                    let param = {
                      "graph": filename,
                      "data": new Date().toJSON().slice(0, 10).split("-").reverse().join("/"),
                      "utente": "REPARTI: " + repartiNome.join(" - "),
                      "commento": "",
                      "attendibilita": "",
                      "forzatura": "",
                      "versione": q.id,
                      "questionario": quest.titolo,
                      "filename": licenza.id + repartiId.join("-"),
                      "copertina": copertina == null ? "" : q.copertina_report["ITA"],
                      "url": baseUrl,
                      "totCompilations": nCompilazioni
                    };

                    this.node.getData(param, "/quest/export").subscribe(
                      (data) => {
                        if (data.status !== 200) {
                          var file = new Blob([data], { type: "application/pdf" });
                          // var file = new Blob([data], { type: "text/html" });

                          var fileURL = URL.createObjectURL(file);
                          const a = document.createElement("a");
                          document.body.appendChild(a);
                          a.href = fileURL;
                          a.download = "valutazione" + licenza.id + repartiId.join("-") + ".pdf";
                          a.target = "_blank";
                          a.click();
                          this.chartProgress = false;
                        }
                      },
                      (err) => {
                        this.chartVisible = false;
                        this.chartProgress = false;
                        // console.log("err", err);
                      }
                    );
                  }
                },
                (err) => {
                  this.chartVisible = false;
                  this.chartProgress = false;
                  // console.log("err", err);
                }
              );
            }, 500);
          }
        });
      });
    });
  }

  excelExport(dataV, aId, lId, comment = false) {
    // this.chartProgress = true;
    let params = {
      aId: aId,
      lId: lId,
      cId: dataV.id,
    };
    let data: any[] = [];
    this.fire
      .getSubDoc("clienti", params.aId, "licenze", params.lId, "compilazioni", dataV.compilazione.id)
      .subscribe((res) => {
        data[0] = res;
        data[0].intestazione = Object.keys(data[0].intestazione)
          .sort()
          .reduce((obj, key) => {
            obj[key] = data[0].intestazione[key];
            return obj;
          }, {});

        let fileName = '';

        this.fire
          .getDocument("clienti", data[0].licenza.azienda, "licenze", data[0].licenza.licenza)
          .subscribe((lic) => {
            this.fire.get("questionari", lic.questionario).subscribe((quest) => {
              this.fire.getDocument("questionari", lic.questionario, "versioni", lic.versione).subscribe((q) => {
                // ######################################### EXPORT PER EXCEL
                //// console.log("DOMANDE", q.domande);
                //// console.log("RISPOSTE", data[0].domande);
                let outCsv = "";
                data[0].domande.forEach((r) => {
                  // console.log(r);
                  let qIndex = q.domande.findIndex((d) => d.codice == r.domanda);
                  if (qIndex > -1) {
                    let rIndex = q.domande[qIndex].risposte.findIndex((risp) => risp.codice === r.risposta);
                    if (rIndex >= 0) {
                      // CONSOLE DI CONTROLLO RISULTATI
                      // console.log(r, q.domande[qIndex].risposte[rIndex], q.domande[qIndex].risposte[rIndex].titolo.ITA);
                      if (comment) {
                        if (r.commento === '') r.commento = '""';
                        outCsv += r.domanda.substr(1) + "\t" + r.commento + "\n";
                        fileName = 'commenti';
                      } else {
                        fileName = 'risposte';
                        outCsv += r.domanda.substr(1) + "\t" + q.domande[qIndex].risposte[rIndex].titolo.ITA + "\n";
                      }
                    }
                  }
                });

                fileName += aId + lId + dataV.id + '.csv';

                this.fileExcelExport(outCsv, fileName);

                // const selBox = document.createElement("textarea");
                // selBox.style.position = "fixed";
                // selBox.style.left = "0";
                // selBox.style.top = "0";
                // selBox.style.opacity = "0";
                // selBox.value = outCsv;
                // document.body.appendChild(selBox);
                // selBox.focus();
                // selBox.select();
                // document.execCommand("copy");
                // document.body.removeChild(selBox);

                // this.message.success('');
                this.chartProgress = false;
              });
            });
          });
      });
  }

  // ######################################
  // FUNCTION: chartExport
  chartExport(dataV, aId, lId) {
    this.chartProgress = true;
    let params = {
      aId: aId,
      lId: lId,
      cId: dataV.id,
    };
    let data: any[] = [];
    this.fire
      .getSubDoc("clienti", params.aId, "licenze", params.lId, "compilazioni", dataV.compilazione.id)
      .subscribe((res) => {
        data[0] = res;
       
        this.fire
          .getDocument("clienti", data[0].licenza.azienda, "licenze", data[0].licenza.licenza)
          .subscribe((lic) => {
            this.fire.get("questionari", lic.questionario).subscribe((quest) => {
              this.fire.getDocument("questionari", lic.questionario, "versioni", lic.versione).subscribe((q) => {
               
                q.tratti.forEach((t, tIndex: number) => {
                  let pCount = 0;

                  data[0].domande.forEach((d) => {
                    let p = t.punteggi[d.domanda];
                    if (p) {
                      let dIndex = p.domanda.risposte.findIndex((di) => di.codice === d.risposta);
                      if (dIndex >= 0) {
                        let r = p.domanda.risposte[dIndex];
                        pCount += parseFloat(r.valore) || 0;
                      }
                    }
                  });

                  let tCount = pCount;
                  this.chartType = this.chartBarType;
                  this.chartDisplayOptions = this.chartBarPrintOptions;
                  this.chartColumnNames = this.chartBarColumnNames;

                  if (q["report"] == 2) {

                    this.chartType = this.chartLineType;
                    this.chartDisplayOptions = this.chartLinePrintOptions;
                    this.chartColumnNames = this.chartLineColumnNames;

                    // ######################## SCALATURA NUOVO ALGORITMO
                    const scalato = ((tCount - t.min) / (t.max - t.min)) * 200 - 100;
                    tCount = Math.round(scalato);

                  } else if (t.transform) {
                    let pIndex = t.transform.findIndex((tr) => tr.punti === pCount);

                    if (pIndex > -1) {
                      tCount = t.transform[pIndex].trasformato;
                    }
                  }
                  this.chartData[tIndex] = [];
                  this.chartData[tIndex].push(t.titolo["ITA"]);
                  this.chartData[tIndex].push(tCount);
                  if (q["report"] != 2) {
                    if (t.gruppo === 1) {
                      this.chartData[tIndex].push("color: #FD0000;");
                    } else if (t.gruppo === 2) {
                      this.chartData[tIndex].push("color: #E97949;");
                    } else if (t.gruppo === 3) {
                      this.chartData[tIndex].push("color: #00B0F0;");
                    } else if (t.gruppo === 4) {
                      this.chartData[tIndex].push("color: #92D050;");
                    } else if (t.gruppo === 5) {
                      this.chartData[tIndex].push("color: #FFFF00;");
                    } else {
                      this.chartData[tIndex].push("color: #661615;");
                    }
                  }
                  this.chartData[tIndex].push(tCount);
                  this.chartData[tIndex].push("");

                  if (tIndex === q.tratti.length - 1) {
                    this.chartVisible = true;
                    setTimeout(() => {
                      let filename = aId + lId + dataV.id + "-chart.svg";

                      let docEl: any = document.getElementById("chart");
                      docEl = document.querySelector("svg");
                      docEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
                      docEl.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
                      const formData = {
                        "file": docEl.outerHTML,
                        "filename": filename
                      };

                      formData.file = formData.file.replace(/<path [^>]*?stroke="[^"]*"/g, (match) => {
                        return match.replace(/stroke="[^"]*"/, 'stroke="#000000"');
                      });

                      const data = this.node.crypto(formData);

                      let baseUrl: string;
                      if (location.hostname === "localhost" || location.hostname === "127.0.0.1" || location.hostname === "collapp.amajorsb.com") {
                        baseUrl = "https://collnode.amajorsb.com";
                      } else {
                        baseUrl = "https://node.amajorsb.com";
                      }
                      const req = new HttpRequest("POST", baseUrl + "/quest/upload/charts", { data }, {
                        reportProgress: true,
                      });
                      return this.http.request(req).subscribe(
                        (event: HttpEvent<any>) => {
                          this.chartVisible = false;

                          if (event instanceof HttpResponse) {
                            var copertina = q.copertina_report["ITA"];
                            let param = {
                              "graph": filename,
                              "data": new Date().toJSON().slice(0, 10).split("-").reverse().join("/"),
                              "utente": dataV.descrizione,
                              "commento": dataV.compilazione.commento ?? '',
                              "attendibilita": dataV.compilazione.attendibilita,
                              "forzatura": dataV.compilazione.forzatura,
                              "versione": q.id,
                              "questionario": quest.titolo,
                              "filename": aId + lId + dataV.id,
                              "copertina": copertina == null ? "" : q.copertina_report["ITA"],
                              "url": baseUrl,
                              "totCompilations": -1
                            };

                            this.node.getData(param, "/quest/export").subscribe(
                              (data) => {
                                if (data.status !== 200) {
                                  var file = new Blob([data], { type: "application/pdf" });
                                  // var file = new Blob([data], { type: "text/html" });

                                  var fileURL = URL.createObjectURL(file);
                                  const a = document.createElement("a");
                                  document.body.appendChild(a);
                                  a.href = fileURL;
                                  a.download = "valutazione" + aId + lId + dataV.id + ".pdf";
                                  a.target = "_blank";
                                  a.click();
                                  this.chartProgress = false;
                                }
                              },
                              (err) => {
                                this.chartVisible = false;
                                this.chartProgress = false;
                                // console.log("err", err);
                              }
                            );
                          }
                        },
                        (err) => {
                          this.chartVisible = false;
                          this.chartProgress = false;
                          // console.log("err", err);
                        }
                      );
                    }, 500);
                  }
                });
              });
            });
          });
      });
  }
  // ######################################
  // FUNCTION: checkEmail
  checkEmail(email: string): boolean {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }
  // ######################################
  // FUNCTION: checkEmail
  checkMobile(mobile: string): boolean {
    return /^((00|\+)39[\. ]??)??3\d{2}[\. ]??\d{6,7}$/.test(mobile);
  }

  async userSendLinkAmajor(azienda, licenza, user, license, anonimo = false): Promise<number> {
    const that = this;

    // Validate inputs
    if (!azienda || !licenza || !user || !user.address) {
      throw new Error("Invalid inputs");
    }

    let customLink = {
      azienda: azienda,
      licenza: licenza.id,
      reparto: user.roleCode,
      utente: user.nameId,
      tecnico: licenza.tecnico
    };

    let encodedUrl = that.CryptoJS.enc.Base64.stringify(that.CryptoJS.enc.Utf8.parse(JSON.stringify(customLink)));

    let licenzeUtilizzate = license;
    let isEmail = user.address.includes('@');
    let formData = {};

    if (isEmail) {
      formData = {
        user: user.address,
        link: encodedUrl,
      };
    } else {
      formData = {
        user: user.name,
        link: encodedUrl,
        mobile: user.address.substring(3),
        pin: that.uniqueNumber(4, 4),
        timestamp: Date.now(),
      };
    }

    const sendNotification = async (formData, customLink) => {
      if (isEmail) {
        return await that.sendMail(formData, false);
      } else {
        return await that.sendSms(customLink, formData, false);
      }
    };

    const updateDatabase = async (customLink, licenzeUtilizzate) => {
      await that.fire.addSubCollection(
        "clienti",
        "licenze",
        "compilazioni",
        { sent: true, utente: customLink.utente, reparto: customLink.reparto },
        customLink.azienda,
        customLink.licenza,
        customLink.reparto + customLink.utente
      );

      await that.fire.update(
        "clienti",
        "licenze",
        { 'utilizzate': licenzeUtilizzate },
        customLink.azienda,
        customLink.licenza
      );
    };

    try {
      if (!user.isSended) {
        licenzeUtilizzate += 1;
        await updateDatabase(customLink, licenzeUtilizzate);
        await sendNotification(formData, customLink);
        return licenzeUtilizzate;
      } else {
        if (isEmail) {
          await that.sendMailUpdateDb(user, customLink, formData, false);
        } else {
          await that.sendSmsUpdateDb(user, customLink, formData, false);
        }
        return licenzeUtilizzate;
      }
    } catch (error) {
      console.error("Error in userSendLinkAmajor:", error);
      throw error;
    }
  }


  modalResetLink() {

  }

  // ######################################
  // FUNCTION: userSendMail
  userSendMail(azienda, licenza, user, anonimo: boolean = false) {
    const that = this;
    return new Promise(function (resolve) {
      let customLink = {
        azienda: azienda.id,
        licenza: licenza.id,
        reparto: user.reparto,
        utente: user.id,
        tecnico: licenza.tecnico
      };
      let encodedUrl = that.CryptoJS.enc.Base64.stringify(that.CryptoJS.enc.Utf8.parse(JSON.stringify(customLink)));

      const formData = {
        user: user.email,
        link: encodedUrl,
      };

      that.fire
        .getSubDocOnce(
          "clienti",
          customLink.azienda,
          "licenze",
          customLink.licenza,
          "compilazioni",
          customLink.reparto + customLink.utente
        )
        .subscribe(async (comp) => {
          let compData = comp.data();
          if (compData && compData.sent === true) {
            // QUESTIONARIO INVIATO MA NON INIZIATO
            // console.log("CHECK: questionario inviato ma non utilizzato");
            var res = await that.sendMail(formData);
            resolve(res);
          } else if (compData && !compData.concluso) {
            // QUESTIONARIO INIZIATO MA NON CONCLUSO
            // console.log("CHECK: questionario iniziato ma non concluso");

            that.modal.create({
              nzTitle: anonimo ? "" : user.descrizione + ": " + user.email,
              nzContent:
                "<h1>Questionario in corso</h1>" +
                "<span>Il destinatario ha già iniziato il questionario. Cancellare la compilazione e inviare un nuovo questionario?</span>",
              nzOkText: "Conferma",
              nzOnOk: async () => {
                res = await that.sendMailUpdateDb(user, customLink, formData);
                resolve(res);
              },
              nzOnCancel: () => {
                resolve(false);
              },
              nzCancelDisabled: true,
              nzCancelText: "",
              nzWrapClassName: "innerModal",
            });
          } else if (compData && compData.concluso) {
            // QUESTIONARIO INIZIATO E CONCLUSO
            // console.log("CHECK: questionario iniziato e concluso!");

            //INVITO DA MANDARE, ALMENO UNA LICENZA LIBERA
            licenza.utilizzate += 1;
            // console.log("USATE", licenza.utilizzate);

            await that.fire.update(
              "clienti",
              "licenze",
              { utilizzate: licenza.utilizzate },
              customLink.azienda,
              customLink.licenza
            );

            that.modal.create({
              nzTitle: anonimo ? "" : user.descrizione + ": " + user.email,
              nzContent:
                "<h1>Questionario in corso</h1>" +
                "<span>Questo utente ha già concluso il questionario. Cancellare la compilazione e inviare un nuovo questionario?</span>",
              nzOkText: "Conferma",
              nzOnOk: async () => {
                res = await that.sendMailUpdateDb(user, customLink, formData);
                resolve(res);
              },
              nzOnCancel: () => {
                resolve(false);
              },
              nzCancelDisabled: true,
              nzCancelText: "",
              nzWrapClassName: "innerModal",
            });
          } else if (licenza.utilizzate + 1 > licenza.totali) {
            // LIMITE LICENZE RAGGIUNTO
            // console.log("CHECK: limite licenze raggiunto!");
            that.createErrorNotification("Email non inviata", "Limite licenze raggiunte");
            resolve("STOP");
          } else {
            // NESSUNA RESTRIZIONE
            // console.log("CHECK: nessuna restrizione, procedo");

            //INVITO DA MANDARE, ALMENO UNA LICENZA LIBERA
            licenza.utilizzate += 1;
            await that.fire.update(
              "clienti",
              "licenze",
              { utilizzate: licenza.utilizzate },
              customLink.azienda,
              customLink.licenza
            );
            that.fire
              .addSubCollection(
                "clienti",
                "licenze",
                "compilazioni",
                { sent: true, utente: customLink.utente, reparto: customLink.reparto },
                customLink.azienda,
                customLink.licenza,
                customLink.reparto + customLink.utente
              )
              .then((added) => { })
              .catch((err) => {
                // console.log(err);
                resolve(false);
              });

            that.node.callNodeUrl(formData, "/users/sendLink").subscribe(
              (res) => {
                that.createSuccessNotification("Email inviata con successo", user.email);
                resolve(true);
              },
              (err) => {
                that.createErrorNotification("Problema di invio email!", user.email);
                // console.log("ERR:", err);
                resolve(false);
              }
            );
          }
        });
    });
  }

  sendMailNotification(formData: any, path: string) {
    const that = this;
    return new Promise(function (resolve) {
      that.node.callNodeUrl(formData, path).subscribe(
        (res) => {
           resolve(true);
        },
        (err) => {
          console.error("SEND MAIL:", err);
          resolve(false);
        }
      );
    });
  }

  //#######################################
  sendMail(formData: any, showModal: boolean = true) {
    const that = this;
    return new Promise(function (resolve) {
      that.node.callNodeUrl(formData, "/users/sendLink").subscribe(
        (res) => {
          if (showModal)
            that.createSuccessNotification("Email inviata con successo", formData.email);
          resolve(true);
        },
        (err) => {
          if (showModal)
            that.createErrorNotification("Problema di invio email!", formData.email);
          console.error("SEND MAIL:", err);
          resolve(false);
        }
      );
    });
  }
  //#######################################
  async sendMailUpdateDb(u, customLink: any, formData: any, isModal: boolean = true) {
    const that = this;
    return new Promise(function (resolve) {
      that.node.callNodeUrl(formData, "/users/sendLink").subscribe(
        async (res) => {
          if (isModal)
            that.createSuccessNotification("Email inviata con successo", u.email);
          let data = { sent: true, utente: customLink.utente, reparto: customLink.reparto };
          await that.fire
            .addSubCollection(
              "clienti",
              "licenze",
              "compilazioni",
              data,
              customLink.azienda,
              customLink.licenza,
              customLink.reparto + customLink.utente
            )
            .then((added) => {
              resolve(true);
            })
            .catch((err) => {
              console.error("ERR:", err);
              resolve(false);
            });
        },
        (err) => {
          that.createErrorNotification("Problema di invio email!", u.email);
          console.error("ERR:", err);
          resolve(false)
        }
      );
    });
  }

  // ############################################################################
  // FUNCTION: userSendSms
  uniqueNumber(time, count) {
    var date = Date.now();
    var chars = "0123456789".split("");
    var result = "";
    for (var i = 0; i < count; i++) {
      var x = Math.floor(Math.random() * chars.length);
      result += chars[x];
    }
    return date.toString().substr(13 - time) + result;
  }

  userSendSms(azienda, licenza, user, anonimo: boolean = false) {
    const that = this;
    return new Promise(function (resolve) {
      let customLink = {
        azienda: azienda.id,
        licenza: licenza.id,
        reparto: user.reparto,
        utente: user.id,
        tecnico: licenza.tecnico
      };

      let encodedUrl = that.CryptoJS.enc.Base64.stringify(that.CryptoJS.enc.Utf8.parse(JSON.stringify(customLink)));

      const formData = {
        user: user.email,
        link: encodedUrl,
        mobile: user.mobile,
        pin: that.uniqueNumber(4, 4),
        timestamp: Date.now(),
      };

      that.fire
        .getSubDocOnce(
          "clienti",
          customLink.azienda,
          "licenze",
          customLink.licenza,
          "compilazioni",
          customLink.reparto + customLink.utente
        )
        .subscribe(async (comp) => {
          let compData = comp.data();
          if (compData && compData.sent === true) {
            // QUESTIONARIO INVIATO MA NON INIZIATO
            // console.log("CHECK: questionario inviato ma non utilizzato");
            formData.pin = compData.pin; // REUSE SAME PIN
            var res = await that.sendSms(customLink, formData);
            resolve(res);
          } else if (compData && !compData.concluso) {
            // QUESTIONARIO INIZIATO MA NON CONCLUSO
            // console.log("CHECK: questionario iniziato ma non concluso");

            that.modal.create({
              nzTitle: anonimo ? "" : user.descrizione + ": " + user.mobile,
              nzContent:
                "<h1>Questionario in corso</h1>" +
                "<span>Il destinatario ha già iniziato il questionario. Cancellare la compilazione e inviare un nuovo questionario?</span>",
              nzOkText: "Conferma",
              nzOnOk: async () => {
                res = await that.sendSmsUpdateDb(user, customLink, formData);
                // console.log("RESOLVE", res);
                resolve(res);
              },
              nzOnCancel: () => {
                resolve(false);
              },
              nzCancelDisabled: true,
              nzCancelText: "",
              nzWrapClassName: "innerModal",
            });
          } else if (compData && compData.concluso) {
            // QUESTIONARIO INIZIATO E CONCLUSO
            // console.log("CHECK: questionario iniziato e concluso!");

            //INVITO DA MANDARE, ALMENO UNA LICENZA LIBERA
            licenza.utilizzate += 1;
            // console.log("USATE", licenza.utilizzate);

            await that.fire.update(
              "clienti",
              "licenze",
              { utilizzate: licenza.utilizzate },
              customLink.azienda,
              customLink.licenza
            );

            that.modal.create({
              nzTitle: anonimo ? "" : user.descrizione + ": " + user.mobile,
              nzContent:
                "<h1>Questionario in corso</h1>" +
                "<span>Questo utente ha già concluso il questionario. Cancellare la compilazione e inviare un nuovo questionario?</span>",
              nzOkText: "Conferma",
              nzOnOk: async () => {
                res = await that.sendSmsUpdateDb(user, customLink, formData);
                resolve(res);
              },
              nzOnCancel: () => {
                resolve(false);
              },
              nzCancelDisabled: true,
              nzCancelText: "",
              nzWrapClassName: "innerModal",
            });
          } else if (licenza.utilizzate + 1 > licenza.totali) {
            // LIMITE LICENZE RAGGIUNTO
            // console.log("CHECK: limite licenze raggiunto!");
            that.createErrorNotification("SMS non inviato", "Limite licenze raggiunte");
            resolve("STOP");
          } else {
            // NESSUNA RESTRIZIONE
            // console.log("CHECK: nessuna restrizione, procedo");

            //INVITO DA MANDARE, ALMENO UNA LICENZA LIBERA
            licenza.utilizzate += 1;
            await that.fire.update(
              "clienti",
              "licenze",
              { utilizzate: licenza.utilizzate },
              customLink.azienda,
              customLink.licenza
            );
            res = await that.sendSmsUpdateDb(user, customLink, formData);
            resolve(res);
          }
        });
    });
  }
  //#######################################
  sendSms(customLink: any, formData: any, showModal: boolean = true) {
    const that = this;
    return new Promise(async function (resolve) {
      const data = {
        azienda: customLink.azienda,
        licenza: customLink.licenza,
        reparto: customLink.reparto,
        utente: customLink.utente,
        compilazione: customLink.reparto + customLink.utente,
        timestamp: formData.timestamp,
      };
      // ASS SMS PIN TO DATABASE
      await that.fire
        .add("sms", data, formData.pin)
        .then(async (added) => {
          // SEND SMS PIN TO USER
          await that.node.callNodeUrl(formData, "/users/sendSms").subscribe(
            (res) => {
              if (showModal)
                that.createSuccessNotification("SMS inviato con successo", formData.mobile);
              resolve(true);
            },
            (err) => {
              if (showModal)
                that.createErrorNotification("Problema di invio SMS!", formData.mobile);
              console.error("SEND MAIL:", err);
              resolve(false);
            }
          );
        })
        .catch((err) => {
          that.createErrorNotification("Problema di comunicazione con il server!", "");
          console.error("ERR:", err);
          resolve(false);
        });
    });
  }
  //#######################################
  sendSmsUpdateDb(u, customLink: any, formData: any, isModal: boolean = true) {
    const that = this;
    return new Promise(function (resolve) {
      const data = {
        azienda: customLink.azienda,
        licenza: customLink.licenza,
        reparto: customLink.reparto,
        utente: customLink.utente,
        compilazione: customLink.reparto + customLink.utente,
        timestamp: formData.timestamp,
      };
      // ASS SMS PIN TO DATABASE
      that.fire
        .add("sms", data, formData.pin)
        .then((added) => {
          // SEND SMS PIN TO USER
          that.node.callNodeUrl(formData, "/users/sendSms").subscribe(
            (res) => {
              // NOTIFY SUCCESS AND UPDATE DB
              if (isModal)
                that.createSuccessNotification("SMS inviato con successo", u.mobile);
              const data = { sent: true, utente: customLink.utente, reparto: customLink.reparto, pin: formData.pin };
              that.fire
                .addSubCollection(
                  "clienti",
                  "licenze",
                  "compilazioni",
                  data,
                  customLink.azienda,
                  customLink.licenza,
                  customLink.reparto + customLink.utente
                )
                .then((added) => {
                  resolve(true);
                })
                .catch((err) => {
                  console.error("ERR:", err);
                  resolve(false);
                });
            },
            (err) => {
              // NOTIFY THE ERROR
              that.createErrorNotification("Problema di invio SMS!", u.mobile);
              console.error("ERR:", err);
              resolve(false);
            }
          );
        })
        .catch((err) => {
          that.createErrorNotification("Problema di comunicazione con il server!", "");
          console.error("ERR:", err);
          resolve(false);
        });
    });
  }

  fileExcelExport(outCsv, fileName) {
    // crate a blob
    let blob = new Blob([outCsv], { type: 'text/csv' });
    let blobUrl = URL.createObjectURL(blob);

    // create element a for download
    let a = document.createElement('a');
    a.href = blobUrl;
    a.download = fileName;
    a.style.display = 'none';
    document.body.appendChild(a);

    // simulate click
    a.click();

    // remove a after dowload
    document.body.removeChild(a);

    // clean url
    URL.revokeObjectURL(blobUrl);
  }

  customSort(a, b) {
    // Extract the numeric part of the ad_id (assuming it always starts with 'R')
    const aNum = parseInt(a.id.substring(1));
    const bNum = parseInt(b.id.substring(1));

    // Compare the numeric values
    return aNum - bNum;
  }
}
