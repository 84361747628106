import { Component, ElementRef, OnInit, ViewChild, HostListener, OnDestroy } from "@angular/core";
import { Data, Router } from "@angular/router";
import { StorageService } from "src/app/services/storage.service";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { CompileService } from "src/app/services/compile.service";
import { FireService } from "src/app/services/fire.service";
import { GlobalService } from "src/app/shared/global.service";
import { DocumentData } from "@angular/fire/compat/firestore";
import { type } from "os";
import { Gruppo_forzatura } from "src/app/entities/gruppi_forzatura";
import { Gruppo_attendibilità } from "src/app/entities/gruppi_attendibilita";
@Component({
  selector: "app-domande",
  templateUrl: "./domande.component.html",
  styleUrls: ["./domande.component.scss"],
  animations: [
    trigger("slideOut", [
      state(
        "false",
        style({
          transform: "translateX(0)",
        })
      ),
      state(
        "true",
        style({
          transform: "translateX(-100%)",
        })
      ),
      transition("false => true", animate("500ms ease-in-out")),
    ]),

    trigger("slideIn", [
      state(
        "true",
        style({
          transform: "translateX(-100%)",
        })
      ),
      state(
        "false",
        style({
          transform: "translateX(0%)",
        })
      ),
      transition("true => false", animate("500ms ease-in-out")),
    ]),
  ],
})
export class DomandeComponent implements OnInit, OnDestroy {

  @HostListener('unloaded')
  ngOnDestroy() {
    this.elementRef.nativeElement.remove();
    this.fire.getAllUnsubscribed();
    // console.log('Cleared');
  }

  questTitle: string;
  questSubTitle: string;
  questVersion: string;
  questions: any[] = [];
  answers: any[] = [];
  qIndex = 0;
  backAvailable: boolean = false;
  forwardAvailable: boolean = false;
  in: boolean = false;
  out: boolean = false;
  time: number[] = [];
  start: Date = new Date(Date.now());
  percent: number = 0;

  storageQuest: any;
  user: any;
  showComment = false;
  commento = "";

  isSpinning: boolean = false;
  isError: boolean = false;

  timeoutSpinner: number;
  saveQuestionCounter: number;

  onlineData: DocumentData | undefined;
  onlyComment: boolean = false;

  @ViewChild("textNote") set bannerNoteRef(ref: ElementRef) {
    if (ref) {
      ref.nativeElement.focus();
    }
  }

  // ######################################
  // FUNCTION: constructor
  constructor(
    private storage: StorageService,
    public router: Router,
    public compile: CompileService,
    public g: GlobalService,
    private fire: FireService,
    private elementRef: ElementRef
  ) { }

  // ######################################
  // FUNCTION: ngOnInit
  async ngOnInit() {
    // get save option from db
    await this.getSettingOption();

    this.user = this.storage.getActiveQuest();
    this.setSpinner(true);
    if (this.user) {
      this.fire
        .getSubDocOnce(
          "clienti",
          this.user.azienda,
          "licenze",
          this.user.licenza,
          "compilazioni",
          this.user.reparto + this.user.utente
        )
        .subscribe((comp) => {

          this.onlineData = comp.data();
          // GET QUERY CODE
          this.fire.getDocOnce("clienti", this.user.azienda, "licenze", this.user.licenza).subscribe((res) => {
            if (res && this.onlineData) {
              res = res.data();
              this.questVersion = res.questionario + res.versione;
              this.questTitle = this.onlineData.titoloQ;
              this.questSubTitle =
                "titolo" in this.onlineData.questionario ? this.onlineData.questionario.titolo["ITA"] : null;
              this.questions =
                this.onlineData.questionario.domande == undefined ? [] : this.onlineData.questionario.domande;
              // if (!this.compile.custLogo) {
              //   this.compile.saveLogo(this.onlineData.logo);
              // }
              if (
                typeof this.onlineData.compilazione.last !== "undefined" &&
                this.onlineData.compilazione.last !== null &&
                this.onlineData.compilazione.last > -1
              ) {
                this.qIndex = this.onlineData.compilazione.last + 1;
                this.backAvailable = true;
                this.percent = (this.qIndex / this.questions.length) * 100;
              } else {
                this.onlineData.compilazione.last = -1;
                this.onlineData.compilazione.domande = [];
                this.fire
                  .addSubCollection(
                    "clienti",
                    "licenze",
                    "compilazioni",
                    this.onlineData,
                    this.user.azienda,
                    this.user.licenza,
                    this.user.reparto + this.user.utente
                  )
                  .then((added) => { })
                  .catch((err) => {
                    // console.log(err);
                  });
              }

              // need to check if need only comment
              this.needOnlyComment(this.questions[this.qIndex].risposte, this.qIndex);

              if (this.questions[this.qIndex].risposte.length == 0) this.forwardAvailable = true;
              this.questions.forEach((qu, quIndex) => {
                // CHECK IF ARRAY HAS ORDER
                if (!this.questions[quIndex].ordine) {
                  this.questions[quIndex].ordine = quIndex;
                }

                let excludedQ = false;
                qu.filtri.forEach((f, findex) => {
                  if (!excludedQ) {
                    let userField = this.onlineData!.compilazione.intestazione[f.campo.etichetta];
                    if (f.campo.tipo === "Data") {
                      let userDate = new Date(userField.seconds * 1000);
                      let fieldDate = new Date(f.valore1);
                      if (f.operatore1 === "<") {
                        if (userDate < fieldDate) {
                          excludedQ = true;
                          this.questions.splice(quIndex, 1);
                        }
                      } else if (f.operatore1 === "<=") {
                        if (userDate <= fieldDate) {
                          if (userDate <= fieldDate) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          }
                        }
                      } else if (f.operatore1 === ">") {
                        if (userDate > fieldDate) {
                          excludedQ = true;
                          this.questions.splice(quIndex, 1);
                        }
                      } else if (f.operatore1 === ">=") {
                        if (userDate >= fieldDate) {
                          excludedQ = true;
                          this.questions.splice(quIndex, 1);
                        }
                      } else if (f.operatore1 === "=") {
                        if (userDate === fieldDate) {
                          excludedQ = true;
                          this.questions.splice(quIndex, 1);
                        }
                      }
                    } else if (f.campo.tipo === "Elenco") {
                      let fieldList = f.valore1;
                      let userList: any[] = userField;
                      if (userList.includes(fieldList)) {
                        excludedQ = true;
                        this.questions.splice(quIndex, 1);
                      }
                    } else if (f.campo.tipo === "Booleano") {
                      let fieldBool = f.valore1;
                      let userBool: any[] = userField;
                      if (userBool === fieldBool) {
                        excludedQ = true;
                        this.questions.splice(quIndex, 1);
                      }
                    } else if (f.campo.tipo === "Range") {
                      let fieldRange1 = f.valore1;
                      let fieldRange2 = f.valore2;
                      let userRange = userField;
                      if (f.operatore1 === "<" && userRange < fieldRange1) {
                        if (fieldRange2 && f.operatore2) {
                          if (f.operatore2 === "<" && userRange < fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          } else if (f.operatore2 === "<=" && userRange <= fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          } else if (f.operatore2 === ">" && userRange > fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          } else if (f.operatore2 === ">=" && userRange >= fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          } else if (f.operatore2 === "=" && userRange === fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          }
                        } else {
                          excludedQ = true;
                          this.questions.splice(quIndex, 1);
                        }
                      } else if (f.operatore1 === "<=" && userRange <= fieldRange1) {
                        if (fieldRange2 && f.operatore2) {
                          if (f.operatore2 === "<" && userRange < fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          } else if (f.operatore2 === "<=" && userRange <= fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          } else if (f.operatore2 === ">" && userRange > fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          } else if (f.operatore2 === ">=" && userRange >= fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          } else if (f.operatore2 === "=" && userRange === fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          }
                        } else {
                          excludedQ = true;
                          this.questions.splice(quIndex, 1);
                        }
                      } else if (f.operatore1 === ">" && userRange > fieldRange1) {
                        if (fieldRange2 && f.operatore2) {
                          if (f.operatore2 === "<" && userRange < fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          } else if (f.operatore2 === "<=" && userRange <= fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          } else if (f.operatore2 === ">" && userRange > fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          } else if (f.operatore2 === ">=" && userRange >= fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          } else if (f.operatore2 === "=" && userRange === fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          }
                        } else {
                          excludedQ = true;
                          this.questions.splice(quIndex, 1);
                        }
                      } else if (f.operatore1 === ">=" && userRange >= fieldRange1) {
                        if (fieldRange2 && f.operatore2) {
                          if (f.operatore2 === "<" && userRange < fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          } else if (f.operatore2 === "<=" && userRange <= fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          } else if (f.operatore2 === ">" && userRange > fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          } else if (f.operatore2 === ">=" && userRange >= fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          } else if (f.operatore2 === "=" && userRange === fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          }
                        } else {
                          excludedQ = true;
                          this.questions.splice(quIndex, 1);
                        }
                      } else if (f.operatore1 === "=" && userRange === fieldRange1) {
                        if (fieldRange2 && f.operatore2) {
                          if (f.operatore2 === "<" && userRange < fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          } else if (f.operatore2 === "<=" && userRange <= fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          } else if (f.operatore2 === ">" && userRange > fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          } else if (f.operatore2 === ">=" && userRange >= fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          } else if (f.operatore2 === "=" && userRange === fieldRange2) {
                            excludedQ = true;
                            this.questions.splice(quIndex, 1);
                          }
                        } else {
                          excludedQ = true;
                          this.questions.splice(quIndex, 1);
                        }
                      }
                    }
                  }
                  if (!excludedQ && findex === qu.filtri.length - 1) {
                    qu.risposte.forEach((r, rindex) => {
                      let excludedR = false;
                      r.filtri.forEach((f) => {
                        if (!excludedR) {
                          let userField = this.onlineData!.compilazione.intestazione[f.campo.etichetta];
                          if (f.campo.tipo === "Data") {
                            let userDate = new Date(userField.seconds * 1000);
                            let fieldDate = new Date(f.valore1);
                            if (f.operatore1 === "<") {
                              if (userDate < fieldDate) {
                                excludedR = true;
                                qu.risposte.splice(rindex, 1);
                              }
                            } else if (f.operatore1 === "<=") {
                              if (userDate <= fieldDate) {
                                if (userDate <= fieldDate) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                }
                              }
                            } else if (f.operatore1 === ">") {
                              if (userDate > fieldDate) {
                                excludedR = true;
                                qu.risposte.splice(rindex, 1);
                              }
                            } else if (f.operatore1 === ">=") {
                              if (userDate >= fieldDate) {
                                excludedR = true;
                                qu.risposte.splice(rindex, 1);
                              }
                            } else if (f.operatore1 === "=") {
                              if (userDate === fieldDate) {
                                excludedR = true;
                                qu.risposte.splice(rindex, 1);
                              }
                            }
                          } else if (f.campo.tipo === "Elenco") {
                            let fieldList = f.valore1;
                            let userList: any[] = userField;
                            if (userList.includes(fieldList)) {
                              excludedR = true;
                              qu.risposte.splice(rindex, 1);
                            }
                          } else if (f.campo.tipo === "Booleano") {
                            let fieldBool = f.valore1;
                            let userBool: any[] = userField;
                            if (userBool === fieldBool) {
                              excludedR = true;
                              qu.risposte.splice(rindex, 1);
                            }
                          } else if (f.campo.tipo === "Range") {
                            let fieldRange1 = f.valore1;
                            let fieldRange2 = f.valore2;
                            let userRange = userField;
                            if (f.operatore1 === "<" && userRange < fieldRange1) {
                              if (fieldRange2 && f.operatore2) {
                                if (f.operatore2 === "<" && userRange < fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                } else if (f.operatore2 === "<=" && userRange <= fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                } else if (f.operatore2 === ">" && userRange > fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                } else if (f.operatore2 === ">=" && userRange >= fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                } else if (f.operatore2 === "=" && userRange === fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                }
                              } else {
                                excludedR = true;
                                qu.risposte.splice(rindex, 1);
                              }
                            } else if (f.operatore1 === "<=" && userRange <= fieldRange1) {
                              if (fieldRange2 && f.operatore2) {
                                if (f.operatore2 === "<" && userRange < fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                } else if (f.operatore2 === "<=" && userRange <= fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                } else if (f.operatore2 === ">" && userRange > fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                } else if (f.operatore2 === ">=" && userRange >= fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                } else if (f.operatore2 === "=" && userRange === fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                }
                              } else {
                                excludedR = true;
                                qu.risposte.splice(rindex, 1);
                              }
                            } else if (f.operatore1 === ">" && userRange > fieldRange1) {
                              if (fieldRange2 && f.operatore2) {
                                if (f.operatore2 === "<" && userRange < fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                } else if (f.operatore2 === "<=" && userRange <= fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                } else if (f.operatore2 === ">" && userRange > fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                } else if (f.operatore2 === ">=" && userRange >= fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                } else if (f.operatore2 === "=" && userRange === fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                }
                              } else {
                                excludedR = true;
                                qu.risposte.splice(rindex, 1);
                              }
                            } else if (f.operatore1 === ">=" && userRange >= fieldRange1) {
                              if (fieldRange2 && f.operatore2) {
                                if (f.operatore2 === "<" && userRange < fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                } else if (f.operatore2 === "<=" && userRange <= fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                } else if (f.operatore2 === ">" && userRange > fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                } else if (f.operatore2 === ">=" && userRange >= fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                } else if (f.operatore2 === "=" && userRange === fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                }
                              } else {
                                excludedR = true;
                                qu.risposte.splice(rindex, 1);
                              }
                            } else if (f.operatore1 === "=" && userRange === fieldRange1) {
                              if (fieldRange2 && f.operatore2) {
                                if (f.operatore2 === "<" && userRange < fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                } else if (f.operatore2 === "<=" && userRange <= fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                } else if (f.operatore2 === ">" && userRange > fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                } else if (f.operatore2 === ">=" && userRange >= fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                } else if (f.operatore2 === "=" && userRange === fieldRange2) {
                                  excludedR = true;
                                  qu.risposte.splice(rindex, 1);
                                }
                              } else {
                                excludedR = true;
                                qu.risposte.splice(rindex, 1);
                              }
                            }
                          }
                        }
                      });
                    });
                  }
                });
              });
            }
          });
          this.setSpinner(false);
        });
    } else {
      this.router.navigateByUrl("/compilazione/accesso-vietato");
    }
  }

  // ######################################
  // FUNCTION: getSettingOption
  getSettingOption() {
    return new Promise(async (resolve) => {
      this.fire.getSettingOptions()
        .subscribe(result => {
          this.timeoutSpinner = result![0].timeoutSpinner ?? 10000;
          this.saveQuestionCounter = result![0].domandeSalvataggioDifferito ?? 5;
          resolve(true);
        }, (error) => {
          // console.log(error);
          this.isError = true;
          resolve(true);
        });
    });
  };

  // ######################################
  // FUNCTION: reload
  reLoad() {
    window.location.reload();
  }

  // ######################################
  // FUNCIONT: set spinner
  waitTimeout: NodeJS.Timeout;
  setSpinner(bActive: boolean) {
    const that = this;
    if (bActive) {
      clearTimeout(this.waitTimeout);
      this.waitTimeout = setTimeout(function () {
        // that.isSpinning = false;
        that.isError = true;
      }, that.timeoutSpinner);
      that.isSpinning = true;
    } else {
      clearTimeout(this.waitTimeout);
      that.isSpinning = false;
    }
  }

  // ######################################
  // FUNCTION: forward
  forward() {
    let nRis = this.questions[this.qIndex].risposte;
    let aIndex = this.questions[this.qIndex].risposte.findIndex((a) => a.codice === this.answers[this.qIndex]);

    //// console.log("############", "AINDEX", aIndex, this.questions[this.qIndex].risposte[aIndex]);

    if (aIndex < 0 && nRis.length > 0) {
      this.g.createCustomMessage("warning", "Per procedere è obbligatorio selezionare una risposta.");
    } else if (aIndex >= 0 && this.questions[this.qIndex].risposte[aIndex].commentabile) {
      this.commento = "";
      this.showComment = true;
    } else {
      this.setSpinner(true);

      if (!this.onlineData) {
        this.fire
          .getSubDocOnce(
            "clienti",
            this.user.azienda,
            "licenze",
            this.user.licenza,
            "compilazioni",
            this.user.reparto + this.user.utente
          )
          .subscribe((comp) => {
            this.onlineData = comp.data();
            this.continueForward();
          });
      } else {
        this.needOnlyComment(this.questions[this.qIndex].risposte, this.qIndex);
        this.continueForward();
      }
    }
    this.showComment = false;
    // need to check if need only comment
  }
  continueForward() {
    if (this.onlineData) {
      this.onlineData.compilazione.last = this.qIndex;
      if (this.questions[this.qIndex + 1]) {
        this.addToTimer(this.qIndex);
        this.out = true;
        let index = this.onlineData.compilazione.domande.findIndex(
          (d) => d.domanda === this.questions[this.qIndex].codice
        );
        if (index < 0) {
          this.onlineData.compilazione.domande.push({
            domanda: this.questions[this.qIndex].codice,
            risposta: this.answers[this.qIndex],
            tempo: this.time[this.qIndex],
            ritorni: 0,
            commento: this.commento,
          });
        } else {
          this.onlineData.compilazione.domande[index].risposta = this.answers[this.qIndex];
          this.onlineData.compilazione.domande[index].tempo = this.time[this.qIndex];
          this.onlineData.compilazione.domande[index].ritorni += 1;
          this.onlineData.compilazione.domande[index].commento = this.commento;
        }

        // console.log("# UPDATE QUESTIONARIO", this.qIndex);
        this.setSpinner(true);
        // check if save locally or to db
        if ((this.onlineData.compilazione.domande.length + 1) % this.saveQuestionCounter === 0) {
          this.fire
            .addSubCollection(
              "clienti",
              "licenze",
              "compilazioni",
              this.onlineData,
              this.user.azienda,
              this.user.licenza,
              this.user.reparto + this.user.utente
            )
            .then((res) => {
              // console.log("# UPDATE COMPLETE", this.qIndex);

              if (this.questions[this.qIndex].risposte.length == 0) this.forwardAvailable = true;
              this.setSpinner(false);
              this.showComment = false;
              this.qIndex = this.qIndex + 1;
              this.percent = (this.qIndex / this.questions.length) * 100;
              this.backAvailable = true;
              this.forwardAvailable = false;
              this.out = false;
              this.startTimer(this.qIndex);
              if (this.questions[this.qIndex]) this.needOnlyComment(this.questions[this.qIndex].risposte, this.qIndex);

            })
            .catch((err) => {
              this.setSpinner(false);
              this.isError = true;
              console.error("### ERROR FINAL UPDATE: " + err);
            });
        } else {
          // console.log("# LOCALLY UPDATE COMPLETE", this.qIndex);
          setTimeout(() => {
            if (this.questions[this.qIndex].risposte.length == 0) this.forwardAvailable = true;
            this.setSpinner(false);
            this.showComment = false;
            this.qIndex = this.qIndex + 1;
            this.percent = (this.qIndex / this.questions.length) * 100;
            this.backAvailable = true;
            this.forwardAvailable = false;
            this.out = false;
            if (this.questions[this.qIndex]) this.needOnlyComment(this.questions[this.qIndex].risposte, this.qIndex);
            this.startTimer(this.qIndex);
          }, 300);
        }
        // end check
      } else {
        this.addToTimer(this.qIndex);
        this.onlineData.compilazione.concluso = true;
        let index = this.onlineData.compilazione.domande.findIndex(
          (d) => d.domanda === this.questions[this.qIndex].codice
        );
        if (index < 0) {
          this.onlineData.compilazione.domande.push({
            domanda: this.questions[this.qIndex].codice,
            risposta: this.answers.length > 0 ? this.answers[this.qIndex] : [],
            tempo: this.time[this.qIndex],
            ritorni: 0,
            commento: this.commento,
          });
        } else {
          this.onlineData.compilazione.domande[index].risposta =
            this.answers.length > 0 ? this.answers[this.qIndex] : [];
          this.onlineData.compilazione.domande[index].tempo = this.time[this.qIndex];
          this.onlineData.compilazione.domande[index].ritorni += 1;
          this.onlineData.compilazione.domande[index].commento = this.commento;
        }
        // console.log("# CONCLUSIONE QUESTIONARIO");
        this.setSpinner(true);
        this.fire
          .addSubCollection(
            "clienti",
            "licenze",
            "compilazioni",
            this.onlineData,
            this.user.azienda,
            this.user.licenza,
            this.user.reparto + this.user.utente
          )
          .then((added) => {
            this.setSpinner(false);
            this.lastSave();
          })
          .catch((err) => {
            this.setSpinner(false);
            this.showComment = false;
            this.isError = true;
            console.error("### ERROR FINAL UPDATE: " + err);
          });
      }
    } else {
      this.setSpinner(false);
      this.showComment = false;
      this.isError = true;
      console.error("### ERROR: no online data");
    }

    this.commento = '';
  }

  // ######################################
  // FUNCTION: addComment
  addComment() {
    this.continueForward();
  }

  // ######################################
  // FUNCTION: back
  back() {
    if (this.backAvailable) {
      this.addToTimer(this.qIndex);
      this.qIndex = this.qIndex - 1;

      this.percent = (this.qIndex / this.questions.length) * 100;
      //		this.in = true;
      //		setTimeout(() => {
      //			this.in = false;
      this.backAvailable = false;
      this.forwardAvailable = true;
      this.startTimer(this.qIndex);
      //		}, 0);

      // CHECK COMMENTO
      let aIndex = this.questions[this.qIndex].risposte.findIndex((a) => a.codice === this.answers[this.qIndex]);
      if (aIndex >= 0) {
        const bComm: boolean = this.questions[this.qIndex].risposte[aIndex].commentabile;
        if (bComm) this.showComment = true;
      }
      this.showComment = false;
      this.needOnlyComment(this.questions[this.qIndex].risposte, this.qIndex);
    }
  }

  // ######################################
  // FUNCTION: startTimer
  startTimer(index) {
    if (!this.time[index]) {
      this.time[index] = 0;
    }
    this.start = new Date(Date.now());
  }

  // ######################################
  // FUNCTION: addToTimer
  addToTimer(index) {
    let end = new Date(Date.now());
    if (!this.time[index]) {
      this.time[index] = 0;
    }
    this.time[index] = this.time[index] + (end.getTime() - this.start.getTime());
  }

  needOnlyComment(data, index) {
    if (data.length > 1) {
      this.onlyComment = false;
    } else {
      this.answers[index] = 'R1';
      this.onlyComment = true;
      // this.continueForward();
    }
  }

  lastSave() {
    this.fire
      .getSubDocOnce(
        "clienti",
        this.user.azienda,
        "licenze",
        this.user.licenza,
        "compilazioni",
        this.user.reparto + this.user.utente
      )
      .subscribe((comp) => {
        let q = comp.data();
        if (q) {
          // GET QUERY CODE
          this.fire.getDocOnce("clienti", this.user.azienda, "licenze", this.user.licenza).subscribe((res) => {
            if (res) {
              res = res.data();
              this.questVersion = res.questionario + res.versione;
            }
          });
          this.questTitle = q.titoloQ;
          this.questSubTitle = "titolo" in q.questionario ? q.questionario.titolo["ITA"] : null;

          // calcolo forzatura e attendibilità
          q.compilazione.tratti = [];
          q.compilazione.attendibilita = false;
          q.compilazione.forzatura = false;
          q.compilazione.valore_attendibilita = 0;
          q.compilazione.valore_forzatura = 0;
          this.fire
            .getDocument("clienti", q.compilazione.licenza.azienda, "licenze", q.compilazione.licenza.licenza)
            .subscribe((resL) => {
              this.fire.getDocument("questionari", resL.questionario, "versioni", resL.versione).subscribe((resV) => {
                //trasformo valori tratti
                resV.tratti.forEach((t, tIndex: number) => {
                  let pCount = 0;
                  q!.compilazione.domande.forEach((d) => {
                    let p = t.punteggi[d.domanda];
                    let dIndex = p.domanda.risposte.findIndex((di) => di.codice === d.risposta);
                    if (dIndex >= 0) {
                      let r = p.domanda.risposte[dIndex];
                      pCount += r.valore;
                    }
                  });
                  if (t.transform) {
                    let pIndex = t.transform.findIndex((tr) => tr.punti === pCount);
                    let tCount = 0;
                    if (pIndex > -1) {
                      tCount = t.transform[pIndex].trasformato;
                    }

                    let cTratto = { id: t.codice, punteggio: tCount };
                    q!.compilazione.tratti.push(cTratto);
                  }
                });

                //calcolo attendibilita
                let gAtt: Gruppo_attendibilità[] = resV.gruppi_attendibilita;
                let attMin = resV.indice_attendibilita;
                let attPunt = 0;
                let attControl = true;

                if (gAtt.length > 0) {
                  gAtt.forEach((gA, gAIndex) => {
                    gA.operazioni.forEach((gOp, gOpIndex) => {
                      if (attControl === true) {
                        let dId = gOp.domanda;
                        let rId = gOp.risposta;
                        let dIndex = q!.compilazione.domande.findIndex((d) => d.domanda === dId);
                        if (q!.compilazione.domande[dIndex].risposta === rId) {
                        } else {
                          attControl = false;
                        }
                      }
                      if (gOpIndex === gA.operazioni.length - 1 && attControl === true) {
                        attPunt += gA.valore;
                      }
                    });
                    if (gAIndex === gAtt.length - 1) {
                      q!.compilazione.valore_attendibilita = attPunt;
                      if (attPunt >= attMin) {
                        q!.compilazione.attendibilita = true;
                      }
                    }
                  });
                } else {
                  q!.compilazione.valore_attendibilita = attPunt;
                  q!.compilazione.attendibilita = true;
                }

                //calcolo forzatura
                let gForz: Gruppo_forzatura[] = resV.gruppi_forzatura;
                let forzMin = resV.indice_forzatura;
                let forzPunt = 0;
                let forzControl = true;

                if (gForz.length > 0) {
                  gForz.forEach((gF, gFIndex) => {
                    gF.operazioni.forEach((fOp, fOpIndex) => {
                      if (forzControl === true) {
                        let tId = fOp.tratto;
                        let tIndex = q!.compilazione.tratti.findIndex((t) => t.id === tId);
                        if (fOp.operatore === "<") {
                          if (q!.compilazione.tratti[tIndex].punteggio < fOp.valore) {
                          } else {
                            forzControl = false;
                          }
                        } else if (fOp.operatore === "<=") {
                          if (q!.compilazione.tratti[tIndex].punteggio <= fOp.valore) {
                          } else {
                            forzControl = false;
                          }
                        } else if (fOp.operatore === "=") {
                          if (q!.compilazione.tratti[tIndex].punteggio === fOp.valore) {
                          } else {
                            forzControl = false;
                          }
                        } else if (fOp.operatore === ">") {
                          if (q!.compilazione.tratti[tIndex].punteggio > fOp.valore) {
                          } else {
                            forzControl = false;
                          }
                        } else if (fOp.operatore === ">=") {
                          if (q!.compilazione.tratti[tIndex].punteggio >= fOp.valore) {
                          } else {
                            forzControl = false;
                          }
                        }
                      }
                      if (fOpIndex === gF.operazioni.length - 1 && forzControl === true) {
                        forzPunt += gF.valore;
                      }
                    });
                    if (gFIndex === gForz.length - 1) {
                      q!.compilazione.valore_forzatura = forzPunt;

                      if (forzPunt >= forzMin) {
                        q!.compilazione.forzatura = true;
                      }
                      this.fire
                        .addSubCollection(
                          "clienti",
                          "licenze",
                          "compilazioni",
                          q!.compilazione,
                          q!.compilazione.licenza.azienda,
                          q!.compilazione.licenza.licenza,
                          q!.compilazione.licenza.reparto + q!.compilazione.licenza.utente
                        )
                        .then((added) => {
                          this.sendNotificationTecnico();
                          this.storage.removeActiveQuest();
                          this.router.navigateByUrl("/compilazione/conclusione");
                        })
                        .catch((err) => {
                          // console.log(err);
                        });
                    }
                  });
                } else {
                  q!.compilazione.valore_forzatura = forzPunt;
                  q!.compilazione.forzatura = true;
                  this.fire
                    .addSubCollection(
                      "clienti",
                      "licenze",
                      "compilazioni",
                      q!.compilazione,
                      q!.compilazione.licenza.azienda,
                      q!.compilazione.licenza.licenza,
                      q!.compilazione.licenza.reparto + q!.compilazione.licenza.utente
                    )
                    .then((added) => {
                      this.storage.removeActiveQuest();
                      this.sendNotificationTecnico();
                      this.router.navigateByUrl("/compilazione/conclusione");
                    })
                    .catch((err) => {
                      // console.log(err);
                    });
                }
              });
            });
        }
      });
  }

  sendNotificationTecnico() {
    let formData = {
      tecnico: this.user.tecnico,
      azienda: this.user.azienda,
      licenza: this.user.licenza,
      reparto: this.user.reparto,
	    utente: this.user.utente,
  }
    if (this.user.tecnico)
      this.g.sendMailNotification(formData, "/users/sendNotificationTecnico");
  }
}



