import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthClienteGuard } from "src/app/guards/authCliente.guard";
import { ImpostaComponent } from "./imposta/imposta.component";
import { RichiediComponent } from "./richiedi/richiedi.component";
import { HomepageComponent } from "./homepage.component";
import { LogoComponent } from "./logo/logo.component";
import { QuestionariComponent } from "./licenze/licenze.component";

const routes: Routes = [
	{
		path: "",
		component: HomepageComponent,
		canActivate: [AuthClienteGuard],
		canActivateChild: [AuthClienteGuard],
		children: [
			{ path: "", component: LogoComponent },
			{ path: "questionari", component: QuestionariComponent },
			{ path: "imposta", component: ImpostaComponent },
			{ path: "richiedi", component: RichiediComponent },
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ClienteHomepageRoutingModule {}
