import { Injectable } from "@angular/core";
import { Questionario } from "../entities/questionari";

@Injectable({
	providedIn: "root",
})
export class StorageService {
	// ######################################
	// FUNCTION: constructor
	constructor() {}

	// ######################################
	// FUNCTION: saveQuest
	saveQuest(quest: Questionario) {
		sessionStorage.setItem("SelectedQuest", JSON.stringify(quest));
	}

	// ######################################
	// FUNCTION: getQuest
	getQuest() {
		let tempQ: string | null = sessionStorage.getItem("SelectedQuest");
		if (tempQ) {
			return JSON.parse(tempQ);
		} else {
			return JSON.parse("{}");
		}
	}

	// ######################################
	// FUNCTION: setActiveQuest
	setActiveQuest(quest: Questionario) {
		localStorage.setItem("AmajorCompiledQuest", JSON.stringify(quest));
	}

	// ######################################
	// FUNCTION: getActiveQuest
	getActiveQuest() {
		let activeQ: string | null = localStorage.getItem("AmajorCompiledQuest");
		if (activeQ) {
			return JSON.parse(activeQ);
		} else {
			return null;
		}
	}


	setUser(data) {
		localStorage.setItem("user", JSON.stringify(data));
	}


	

	// ######################################
	// FUNCTION: getActiveQuest
	getUser() {
		let activeQ: string | null = localStorage.getItem("user");
		if (activeQ) {
			return JSON.parse(activeQ);
		} else {
			return null;
		}
	}

	// ######################################
	// FUNCTION: existActiveQuest
	existActiveQuest() {
		let activeQ: string | null = localStorage.getItem("AmajorCompiledQuest");
		if (activeQ) {
			return true;
		} else {
			return false;
		}
	}

	// ######################################
	// FUNCTION: removeActiveQuest
	removeActiveQuest() {
		localStorage.removeItem("AmajorCompiledQuest");
	}
}
