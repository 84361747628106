import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-richiedi',
  templateUrl: './richiedi.component.html',
  styleUrls: ['./richiedi.component.scss'],
})
export class RichiediComponent implements OnInit {
  validateForm!: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      name: [null, [Validators.required]],
      subject: [null, [Validators.required]],
      message: [null, [Validators.required]],
    });
  }

  submitForm(): void {
    if (this.validateForm.valid) {
      console.log('Form submitted successfully!', this.validateForm.value);
      // Handle your form submission logic here (e.g., sending the data to a server)
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsTouched(); // Mark fields as touched to trigger validation messages
        }
      });
    }
  }
}
