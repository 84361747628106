<div class="uiCompilazioneQuestionsContainer">
  <nz-layout class="uiQuestionsInnerLayout" *ngIf="questions.length > 0">
    <nz-header class="uiCompilazioneQuestionsHeader">
      <!-- <img *ngIf="compile.custLogo" [src]="compile.custLogo" alt="Logo" class="uiHeaderCustLogo"> -->
      <!-- <div class="uiQuestTitle" [ngStyle]="{'text-align': (compile.custLogo) ? 'center' : 'left'}">
        <span *ngIf="questTitle" class="uiTitleMain">{{questTitle}}</span><br><span class="uiSubtitleMain"
          *ngIf="questVersion">{{questVersion}}<span *ngIf="questSubTitle"> - </span></span> <span *ngIf="questSubTitle"
          class="uiSubtitleMain">{{questSubTitle}}</span>
      </div> -->
      <img src="../../../../assets/logo-light.png" alt="Logo Amajor" class="uiHeaderLogo">
    </nz-header>

    <nz-progress class="uiCompilazioneProgress " nzStatus="active" [nzPercent]="percent"
      [nzShowInfo]="false"></nz-progress>

    <nz-content class="uiCompilazioneQuestionsContent" [@slideOut]="out" [@slideIn]="in" *ngIf="questions[qIndex]">
      <span class="uiQuestionTitle uiAnswersRadio">{{questions[qIndex].titolo.ITA}}</span>
      <nz-radio-group  [ngClass]="!onlyComment ? '' : 'd-none'" class="uiAnswersRadio" [(ngModel)]="answers[qIndex]" (ngModelChange)="forward()">
        <label class="prevent-select" [style]="answers[qIndex] == a.codice ? 'background-color: var(--primary-color)' : ''" nz-radio *ngFor="let a of questions[qIndex].risposte" [nzValue]="a.codice">{{a.titolo.ITA}}</label>
      </nz-radio-group>
      <div #textNote class="comment-container" >
        <textarea class="comment" [(ngModel)]="commento" *ngIf="showComment || onlyComment" class="uiAnswerTextarea" nz-input
          placeholder="Inserisci un commento" [nzAutosize]="{ minRows: 3, maxRows: 5 }"></textarea>
      </div>
      <br />
      <button id="rispondi-button" nz-button *ngIf="showComment || onlyComment" class="uiAnswerBtn" (click)="addComment()" [disabled]="commento === ''"
        [nzSize]="'large'">Rispondi</button>
    </nz-content>
    <nz-content class="uiCompilazioneQuestionsContent" class="uiSecondContent" *ngIf="questions[qIndex + 1]">
      <span class="uiQuestionTitle">{{questions[qIndex + 1].titolo.ITA}}</span>
      <nz-radio-group [ngClass]="!onlyComment ? '' : 'd-none'" class="uiAnswersRadio">
        <label  class="prevent-select" nz-radio *ngFor="let a of questions[qIndex + 1].risposte" [nzValue]="a.codice">{{a.titolo.ITA}}</label>
      </nz-radio-group>
    </nz-content>


    <nz-footer class="uiCompilazioneQuestionsFooter ">
      <div nz-row>
        <div class="prev-btn" nz-col nzSpan="8">
          <i class="uiBackIcon" nz-icon nzType="left" nzTheme="outline" *ngIf="backAvailable" (click)="back()"></i>
        </div>
        <div nz-col nzSpan="8">

          <!-- <div class="uiCenterText">
                        <div>D-{{questions[qIndex].ordine+1}}</div>
                    </div>-->
        </div>
        <div class="next-btn" nz-col nzSpan="8">
          <i class="uiForwardIcon" nz-icon nzType="right" nzTheme="outline" *ngIf="forwardAvailable && !showComment"
            (click)="forward()"></i>
        </div>
      </div>
    </nz-footer>
  </nz-layout>
</div>



<div class="waiter" *ngIf="isSpinning">
  <span class="ant-spin-dot ant-spin-dot-spin ng-star-inserted"><i class="ant-spin-dot-item"></i><i
      class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i></span>
</div>
