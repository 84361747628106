<nz-layout class="uiPageInnerLayout">
  <div class="form-container">
    <form [formGroup]="validateForm" (ngSubmit)="submitForm()">
      <h1>Richiedi Informazioni</h1>

      <!-- Nome -->
      <div class="form-item">
        <label for="name" class="form-label required">Nome</label>
        <div class="form-control">
          <input formControlName="name" id="name"
            placeholder="Inserisci il tuo nome" />
          <div
            class="error-tip"
            *ngIf="validateForm.get('name')?.invalid && (validateForm.get('name')?.dirty || validateForm.get('name')?.touched)">
            Per favore, inserisci il tuo nome!
          </div>
        </div>
      </div>

      <!-- Oggetto -->
      <div class="form-item">
        <label for="subject" class="form-label required">Oggetto</label>
        <div class="form-control">
          <input formControlName="subject" id="subject"
            placeholder="Inserisci l'oggetto" />
          <div
            class="error-tip"
            *ngIf="validateForm.get('subject')?.invalid && (validateForm.get('subject')?.dirty || validateForm.get('subject')?.touched)">
            Per favore, inserisci un oggetto valido!
          </div>
        </div>
      </div>

      <!-- Messaggio -->
      <div class="form-item">
        <label for="message" class="form-label required">Messaggio</label>
        <div class="form-control">
          <textarea formControlName="message" id="message" rows="4"
            placeholder="Scrivi qui il tuo messaggio..."></textarea>
          <div
            class="error-tip"
            *ngIf="validateForm.get('message')?.invalid && (validateForm.get('message')?.dirty || validateForm.get('message')?.touched)">
            Per favore, inserisci il tuo messaggio!
          </div>
        </div>
      </div>

      <!-- Submit Button -->
      <div class="form-item">
        <div class="form-control offset">
          <button type="submit" [disabled]="validateForm.invalid">Invia
            Richiesta</button>
        </div>
      </div>
    </form>
  </div>
</nz-layout>