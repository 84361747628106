import { Component, OnInit } from "@angular/core";
import { AuthClienteService } from "src/app/services/authCliente.service";
import { GlobalService } from "src/app/shared/global.service";


@Component({
  selector: 'app-imposta',
  templateUrl: './imposta.component.html',
  styleUrls: ['./imposta.component.scss'],
})


export class ImpostaComponent implements OnInit {

  tabImposta = [];// ['Generale', 'Collaboratori' ,'Sicurezza', 'Fatturazione' ]; // Example tab names
  showPasswordModal = false;
  isSaveVisible = true; // Used to control visibility of "Salva" button
  firstName = '';
  email = '';
  phone = '';
  newPassword = '';
  confirmPassword = '';

  tabChanged(event: any) {
    console.log('Tab changed:', event);
  }

  openPasswordModal() {
    this.showPasswordModal = true;
  }

  closePasswordModal() {
    this.showPasswordModal = false;
  }

  setPassword() {
    if (this.newPassword === this.confirmPassword) {
      console.log('Password set successfully.');
      this.closePasswordModal();
    } else {
      alert('Passwords do not match!');
    }
  }

  onSubmit() {
    // Logic to handle form submission
    console.log('Form submitted:', {
      firstName: this.firstName,
      email: this.email,
      phone: this.phone
    });
  }

  constructor(private g: GlobalService, private auth: AuthClienteService) {
    let dati = this.auth.getUser()

    this.firstName = dati.descrizione;
    this.email = dati.email;
    this.phone = dati.mobile;
  }

  ngOnInit(): void {

  }
}



